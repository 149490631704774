<template>
  <div class="account-side tw-flex flex-fixed">
    <div class="tw-flex tw-flex-col account-side__hero tw-h-screen tw-relative">
      <div class="account-side__hero__content tw-m-auto tw-text-white tw-text-center tw-px-4">
        <picture
          class="account-side__hero__content__logo tw-mb-8 tw-hidden md:tw-inline-block"
        >
          <source
            srcset="@/assets/img/logo-icon-white.webp"
            type="image/webp"
          >
          <img
            width="50"
            height="50"
            src="@/assets/img/logo-icon-white.png"
            alt=""
          >
        </picture>

        <h1
          v-text="$t('account.profile.titles.settings')"
          class="tw-text-2xl tw-font-light tw-mb-4"
          data-test="title"
        />
        <p
          v-text="$t('account.profile.paragraphs.settings')"
          class="tw-text-sm"
          data-test="description"
        />
      </div>
    </div>
    <div class="account-side__menu tw-bg-white tw-overflow-y-auto tw-flex tw-flex-col">
      <div class="account-side__menu__header">
        <ctk-user-info />
      </div>

      <nav class="account-side__menu__items">
        <div class="px-3 pb-2 pt-1 account-side__menu__items__btn-wrap">
          <button
            class="btn tw-font-medium tw-w-full account-side__menu__items__btn"
            @click="open = !open"
          >
            <div class="tw-flex tw-justify-between">
              {{ tabs.find(tab => $route.name === tab.name).title }}
              <ui-material-icon
                :name="open ? 'arrow_drop_up': 'arrow_drop_down'"
                data-test="arrow-icon"
              />
            </div>
          </button>
        </div>
        <ul
          :class="{
            open
          }"
          class="tw-pb-4"
        >
          <li
            v-for="(tab, k) in tabs"
            :key="k"
          >
            <router-link
              :to="{
                name: tab.name
              }"
              :title="tab.title"
              v-text="tab.title"
              class="account-side__menu__items__item tw-w-full tw-truncate tw-no-underline tw-block"
              :data-test="tab.name"
              @click.native="open = false"
            />
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CtkUserInfo from '@/components/CtkUser/CtkUserInfo/index.vue'

  /**
   * @module component - accountSide
   */
  export default {
    name: 'AccountSide',
    components: {
      CtkUserInfo
    },
    data () {
      return {
        open: false
      }
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      /**
       * @function tabs
       * @returns {Array<{
       *  name: string,
       *  title: string|import('vue-i18n').TranslateResult|import('vue-i18n')
       * }>}
       */
      tabs () {
        return [
          {
            name: 'AccountProfile',
            title: this.$t('account.profile.title')
          },
          {
            name: 'AccountCompany',
            title: this.$t('account.company.title')
          },
          {
            name: 'AccountMembers',
            title: this.$t('account.members.title')
          },
          // @ts-ignore
          ...(this.isUserShipper ? [
            {
              name: 'AccountPaymentSources',
              title: this.$t('account.payment-sources.title')
            }
          ] : []),
          {
            name: 'AccountBilling',
            title: this.$t('account.billing.title')
          },
          {
            name: 'AccountPassword',
            title: this.$t('account.password.title')
          },
          {
            name: 'AccountLocale',
            title: this.$t('account.locale.title')
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>

  .account-side {
    height: 100%;
    height: 100vh;

    &__hero {
      width: 380px;
      background-image: url('~@/assets/img/backgrounds/account.jpg');
      background-size: cover;
      background-position: center;

      &::before {
        position: absolute;
        content: '';
        background-color: rgba(black, 0.4);
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }

      &__content {
        z-index: 1;
      }
    }

    &__menu {
      width: 380px;
      z-index: 1;
      box-shadow: 0 10px 7px 0 rgba(0, 0, 0, 0.1);
      padding-top: 140px;
      -webkit-overflow-scrolling: touch;

      &__header,
      &__items a {
        padding: 0 30px;
      }

      &__items {
        margin-top: 50px;
        border-top: 1px solid $divider;

        &__btn {
          background-color: black;
          color: white;
          min-height: 45px;

          &-wrap {
            display: none;
          }

          &:focus {
            box-shadow: 0 0 0 0.2rem rgba(black, 0.5) !important;
          }
        }

        ul,
        li {
          margin: 0;
          padding: 0;
          list-style-type: none;
        }

        ul {
          &.open {
            display: block;
            padding: 0 16px;
          }
        }

        a {
          height: 55px;
          line-height: 55px;
          border-bottom: 1px solid $divider;
          color: $secondary-text;
          transition: background-color 200ms, color 200ms;

          &:hover {
            background-color: $light-gray;
          }

          &.router-link-active {
            color: $primary-text;
            background-color: darken($light-gray, 5%);
            font-weight: 500;
          }
        }
      }

      &__mobile {
        display: none;
      }
    }

    @media only screen and (max-width: 1225px) {
      flex-direction: column;

      &__hero {
        height: 250px;
      }

      &__menu {
        padding-top: 32px;
        flex: 1;
      }
    }

    @media only screen and (max-width: 857px) {
      height: auto;

      &__hero {
        height: 200px;
      }

      &__hero,
      &__menu {
        width: 100%;
      }

      &__menu {
        box-shadow: none;

        &__header {
          padding: 0 16px;
        }

        &__items {
          border-top: none;

          &__btn-wrap {
            display: flex;
          }

          ul {
            display: none;
          }
        }

        &__mobile {
          display: flex;
        }
      }
    }
  }

</style>
